import * as ReactHabitat from 'react-habitat';
import {HelloWorld} from './HelloWorld';

import * as serviceWorker from './serviceWorker';
import './index.css';

// Define an array of containers with the HTML ID's to attach to
const containers = [
  {
    id: "HelloWorld",
    component: HelloWorld
  }
];

class MyApp extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    // Create a new container builder:
    const builder = new ReactHabitat.ContainerBuilder();

    // Register container components
    for (let container of containers) {
      builder.register(container.component).as(container.id);
    }

    // Finally, set the container:
    this.setContainer(builder.build());
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Always export a 'new' instance so it immediately evokes:
export default new MyApp();
